import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {connect, useSelector} from "react-redux";
import { Switch } from '@headlessui/react'
import Loading from "../../common/Loading";
import * as api from "../../../api/api";
import {resultBoolean, convertBoolean} from "../../common/Global";
import {getSportsBaseList} from "../../../api/api";
import {CModal} from "@coreui/react";
import UserPointModal from "./UserPointModal";
import UserMoneyModal from "./UserMoneyModal";
import numeral from "numeral";

// 공통으로 사용되는 RollingRateInput 컴포넌트
const RollingRateInput = ({ item, onChange }) => {
    return (
        <div className={"border rounded p-2"}>
            <div className={"bg-gray-100 p-2 mb-2 font-bold text-sm"}>{item.gameNm}</div>
            <div className={"flex flex-col gap-2"}>
                <div className={"flex flex-col gap-1"}>
                    <span className={"text-sm"}>적중할 때 부과</span>
                    <div className={"flex flex-row items-center gap-1"}>
                        <input 
                            className={"w-[50px] border px-1 text-sm"}
                            type="text"
                            value={item.rollingRate}
                            onChange={(e) => onChange(e.target.value, item)}
                        />
                        <span className={"text-sm text-blue-500"}>% (상위수수료율 : {item.recommenderRollingRate})</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

// 게임 섹션 컴포넌트
const GameSection = ({ title, gameDiv, defaultRate, setDefaultRate, userInfo, setUserInfo }) => {
    const handleRateChange = (inputValue, item) => {
        if (inputValue === '' || isNaN(inputValue)) {
            updateRollingRate(inputValue, item);
            return;
        }

        const numValue = parseFloat(inputValue);
        if (numValue > 100) {
            window.alert('100%를 초과할 수 없습니다.');
            return;
        }
        
        if (item.isTopUser === 'N' && numValue > item.recommenderRollingRate) {
            window.alert(`상위수수료율(${item.recommenderRollingRate}%)을 초과할 수 없습니다.`);
            return;
        }

        updateRollingRate(inputValue, item);
    };

    const updateRollingRate = (value, item) => {
        const updatedList = userInfo.userRollingList.map(listItem => 
            listItem.seq === item.seq ? { ...listItem, rollingRate: value } : listItem
        );
        setUserInfo({ ...userInfo, userRollingList: updatedList });
    };

    const applyDefaultRate = () => {
        if (defaultRate === '' || isNaN(defaultRate)) return;
        
        const numValue = parseFloat(defaultRate);
        if (numValue > 100) {
            window.alert('100%를 초과할 수 없습니다.');
            return;
        }

        const updatedList = [...userInfo.userRollingList];
        userInfo.userRollingList.forEach((item, idx) => {
            if (item.gameDiv === gameDiv) {
                if (item.isTopUser === 'N') {
                    if (numValue <= item.recommenderRollingRate) {
                        updatedList[idx] = { ...item, rollingRate: defaultRate };
                    } else {
                        window.alert(`${item.gameNm}의 상위수수료율(${item.recommenderRollingRate}%)을 초과할 수 없습니다.`);
                    }
                } else {
                    updatedList[idx] = { ...item, rollingRate: defaultRate };
                }
            }
        });
        setUserInfo({ ...userInfo, userRollingList: updatedList });
    };

    return (
        <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2 bg-gray-100 p-2">
                <span className="text-sm font-bold">{title} 전체 요율 적용</span>
                <input 
                    className="w-[50px] border px-1 text-sm"
                    type="text"
                    placeholder="요율"
                    value={defaultRate}
                    onChange={(e) => setDefaultRate(e.target.value)}
                />
                <span className="text-sm">%</span>
                <button 
                    className="px-2 py-1 bg-red-500 text-white text-xs rounded"
                    onClick={applyDefaultRate}
                >
                    적용
                </button>
            </div>
            <div className="grid grid-cols-4 gap-4">
                {userInfo.userRollingList
                    .filter(item => item.gameDiv === gameDiv)
                    .map(item => (
                        <RollingRateInput 
                            key={`rolling_${item.seq}`}
                            item={item}
                            onChange={handleRateChange}
                        />
                    ))}
            </div>
        </div>
    );
};

const UserDetail = (props) => {
    const {user} = useSelector((props) => props.account)
    const [loading, setLoading] = useState(false)
    const [searchValue, setSearchValue] = useState("")
    const [userInfo, setUserInfo] = useState({
        companyCode: "", companyType: "", parentId: "", userType: "6000",
        userId: "", userLevel: 1, userName: "", password: "", nickName: "", mobileNo: "", recommendCode: "",
        bankAccount: "", bankName: "", bankOwner: "",
        exchangePass: "", exchangeTime: 0,
        filler1: "",
        recommendYn: 1, bettingYn: 1, moneyRetYn: 1, pointRetYn: 1, transferPointYn: 1, blackYn: 0,
        status: 1, useYn: 1,
        upperUserList: [],
        userSportsSetting: {},
        userSportsPer: {},
        userRollingList: []
    })

    const [sportsSetting, setSportsSetting] = useState({
        sportsYn: 1,
        casinoYn: 1,
        slotYn: 1,
        minigameYn: 1,
    })

    const [pointModal, setPointModal] = useState({visible: false, type: "0"})
    const [moneyModal, setMoneyModal] = useState({visible: false, type: "0"})

    const [searchParam] = useSearchParams()
    const userId = searchParam.get("userId") ? searchParam.get("userId") : ""
    const procUserId = searchParam.get("procUserId") ? searchParam.get("procUserId") : ""

    const [casinoDefaultRate, setCasinoDefaultRate] = useState("")
    const [slotDefaultRate, setSlotDefaultRate] = useState("")

    useEffect(() => {
        search()
    }, [])

    const search = () => {
        setLoading(true)
        api.getGUser(userId).then(result => {
            const {data, status, statusText} = result;
            if (status === 200) {
                setUserInfo(data)
                console.log("### data :: ", data)

                if (data.userSportsSetting !== null) {
                    setSportsSetting(data.userSportsSetting)
                }
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getSportsBaseList = () => {
        api.getSportsBaseList().then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                setSportsSetting(data)
            }
        })
    }

    const handleUserInfo = (e) => {
        const {name, value} = e.target;
        setUserInfo({
            ...userInfo,
            [name]: value
        })
    }

    const handleUserGameSetting = (e) => {
        const {name, value} = e.target
    }

    const save = () => {
        if (window.confirm("저장하시겠어요?")) {
            // setLoading(true)
            console.log("### userInfo :: ", userInfo)
            setLoading(true)
            api.putGUser(userInfo).then(result => {
                const {data, status, statusText} = result;
                if (status === 200) {
                    window.alert("정상적으로 처리되었습니다.")
                }
            })
                .catch(ex => {
                    window.alert(ex.message)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const saveSportsSetting = () => {
        if (window.confirm("저장하시겠어요?")) {
            const params = {
                seq: sportsSetting.seq ? sportsSetting.seq : -1,
                userId: userId,
                ...sportsSetting
            }

            setLoading(true)
            api.putSportsSetting(params).then(result => {
                const {data, status, statusText} = result;
                if (status === 200) {
                    window.alert("정상적으로 처리되었습니다.")
                }
            })
                .catch(ex => {
                    window.alert(ex.message)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const saveSportsPer = () => {
        if (window.confirm("저장하시겠어요?")) {
            console.log("### userRollingList :: ", userInfo.userRollingList)

            setLoading(true)
            api.putUserRolling(userInfo.userRollingList).then(result => {
                const {data, status, statusText} = result;
                if (status === 200) {
                    window.alert("정상적으로 처리되었습니다.")
                }
            })
                .catch(ex => {
                    window.alert(ex.message)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const onButtonMenu = (value) => {
        switch (value) {
            case 0:
                setPointModal({visible: true, type: "0"})
                break;
            case 1:
                setPointModal({visible: true, type: "1"})
                break;
            case 2:
                setMoneyModal({visible: true, type: "0"})
                break;
            case 3:
                setMoneyModal({visible: true, type: "1"})
                break;
            case 4:
                if (window.opener) {
                    window.opener.postMessage(userId + "," + "betting", window.location.origin)
                }
                break;
            case 5:
                if (window.opener) {
                    window.opener.postMessage(userId + "," + "deposit", window.location.origin)
                }

                break;
            case 6:
                if (window.opener) {
                    window.opener.postMessage(userId + "," + "withdraw", window.location.origin)
                }

                break;
            case 7:
                if (window.opener) {
                    window.opener.postMessage(userId + "," + "point", window.location.origin)
                }

                break;
            case 8:
                if (window.opener) {
                    window.opener.postMessage(userId + "," + "loginLog", window.location.origin)
                }

                break;
            case 9:

                break;
            case 10:

                break;
            case 11:

                break;
            case 12:

                break;
            case 13:

                break;
        }
    }

    const procMoney = (money) => {
        const params = {
            ...money,
            procUserId: procUserId
        }

        setLoading(true)
        api.postMoney(params).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                window.alert("정상적으로 처리되었습니다.")
                return
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const procPoint = (point) => {
        const params = {
            ...point,
            procUser: procUserId
        }

        console.log("### point :: ", params)

        setLoading(true)
        api.postPoint(params).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                window.alert("정상적으로 처리되었습니다.")
                return
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <div className={"flex flex-col p-2 gap-1"}>
            {loading ? <Loading /> : null}
            <p className={"text-md font-bold"}>회원 관리 > 회원 상세</p>
            <div className={"flex flex-row gap-1"}>
                <button className={"text-sm bg-gray-800 text-white px-2"} onClick={() => onButtonMenu(0)}>포인트 지급</button>
                <button className={"text-sm bg-gray-800 text-white px-2"} onClick={() => onButtonMenu(1)}>포인트 회수</button>
                <button className={"text-sm bg-gray-800 text-white px-2"} onClick={() => onButtonMenu(2)}>보유금 지급</button>
                <button className={"text-sm bg-gray-800 text-white px-2"} onClick={() => onButtonMenu(3)}>보유금 회수</button>
                <button className={"text-sm bg-gray-800 text-white px-2"} onClick={() => onButtonMenu(4)}>배팅 내역</button>
                <button className={"text-sm bg-gray-800 text-white px-2"} onClick={() => onButtonMenu(5)}>입금 내역</button>
                <button className={"text-sm bg-gray-800 text-white px-2"} onClick={() => onButtonMenu(6)}>출금 내역</button>
                <button className={"text-sm bg-gray-800 text-white px-2"} onClick={() => onButtonMenu(7)}>포인트 내역</button>
                <button className={"text-sm bg-gray-800 text-white px-2"} onClick={() => onButtonMenu(8)}>접속 로그</button>
                <button className={"text-sm bg-gray-800 text-white px-2"} onClick={() => onButtonMenu(9)}>쪽지 전송</button>
                <button className={"text-sm bg-gray-800 text-white px-2"} onClick={() => onButtonMenu(10)}>쪽지 내역</button>
                <button className={"text-sm bg-gray-800 text-white px-2"} onClick={() => onButtonMenu(11)}>파트너 변경</button>
                <button className={"text-sm bg-gray-800 text-white px-2"} onClick={() => onButtonMenu(12)}>일별 통계</button>
                <button className={"text-sm bg-gray-800 text-white px-2"} onClick={() => onButtonMenu(13)}>정보 수정 이력</button>
            </div>
            <div className={"border mt-1"}>
                <div className={"flex flex-row p-2 gap-2"}>
                    <span className={"text-sm font-bold"}>회원 아이디</span>
                    <input className={"text-sm border px-2"} value={userId} disabled={true}/>
                    <button className={"bg-gray-700 px-3 rounded text-white text-sm"} onClick={search}>조회</button>
                </div>
            </div>
            <div className={"flex flex-col gap-2"}>
                <div className={"flex flex-col"}>
                    <div className={"flex flex-row justify-between items-center bg-gray-100 p-2"}>
                        <span className={"text-md font-bold"}>유저정보</span>
                        <button className={"rounded text-xs text-white bg-gray-800 px-2 py-1"} onClick={save}>저장</button>
                    </div>
                    <table className={"table-auto border-1"}>
                        <tbody>
                        <tr className={"border-1"}>
                            <td className={"w-[140px]"}>
                                <span className={"text-sm font-bold"}>아이디</span>
                            </td>
                            <td>
                                <span className={"text-sm font-bold"}>{userInfo.userId}</span>
                            </td>
                            <td className={"w-[140px]"}>
                                <span className={"text-sm font-bold"}>휴대폰번호</span>
                            </td>
                            <td>
                                <input name={"mobileNo"}  className={"rounded border-1 px-2 text-sm"} value={userInfo.mobileNo} onChange={handleUserInfo}/>
                            </td>
                        </tr>
                        <tr className={"border-1"}>
                            <td className={"w-[140px]"}>
                                <span className={"text-sm font-bold"}>닉네임</span>
                            </td>
                            <td>
                                <input name={"nickName"}  className={"rounded border-1 px-2 text-sm"} value={userInfo.nickName} onChange={handleUserInfo}/>
                            </td>
                            <td className={"w-[140px]"}>
                                <span className={"text-sm font-bold"}>은행</span>
                            </td>
                            <td>
                                <span className={"text-sm"}>{userInfo.bankName}</span>
                            </td>
                        </tr>
                        <tr className={"border-1"}>
                            <td className={"w-[140px]"}>
                                <span className={"text-sm font-bold"}>이름</span>
                            </td>
                            <td>
                                <input name={"userName"}  className={"rounded border-1 px-2 text-sm"} value={userInfo.userName} onChange={handleUserInfo}/>
                            </td>
                            <td className={"w-[140px]"}>
                                <span className={"text-sm font-bold"}>계좌번호</span>
                            </td>
                            <td>
                                <span className={"text-sm"}>{userInfo.bankAccount}</span>
                            </td>
                        </tr>
                        <tr className={"border-1"}>
                            <td className={"w-[140px]"}>
                                <span className={"text-sm font-bold"}>비밀번호</span>
                            </td>
                            <td>
                                <input name={"password"} className={"rounded border-1 px-2 text-sm"} type={"password"} value={userInfo.password} onChange={handleUserInfo}/>
                            </td>
                            <td className={"w-[140px]"}>
                                <span className={"text-sm font-bold"}>회원레벨</span>
                            </td>
                            <td>
                                <select name={"userLevel"} className={"rounded border-1 px-2 text-sm"} value={userInfo.userLevel} onChange={handleUserInfo}>
                                    <option value={"1"}>1</option>
                                    <option value={"2"}>2</option>
                                    <option value={"3"}>3</option>
                                    <option value={"4"}>4</option>
                                    <option value={"5"}>5</option>
                                    <option value={"6"}>6</option>
                                </select>
                            </td>
                        </tr>
                        <tr className={"border-1"}>
                            <td className={"w-[140px]"}>
                                <span className={"text-sm font-bold"}>보유머니</span>
                            </td>
                            <td>
                            <span className={"text-sm font-bold"}>{numeral(userInfo.userCash).format("0,0")}</span>
                            </td>
                            <td className={"w-[140px]"}>
                                <span className={"text-sm font-bold"}>보유포인트</span>
                            </td>
                            <td>
                                <span className={"text-sm font-bold"}>{numeral(userInfo.userPoint).format("0,0")}</span>
                            </td>
                        </tr>
                        <tr className={"border-1"}>
                            <td className={"w-[140px]"}>
                                <span className={"text-sm font-bold"}>가입코드</span>
                            </td>
                            <td>
                                <input name={"recommendCode"}  className={"rounded border-1 px-2 text-sm"} value={userInfo.recommendCode} onChange={handleUserInfo}/>
                            </td>
                            <td className={"w-[140px]"}>
                                <span className={"text-sm font-bold"}>가입일</span>
                            </td>
                            <td>
                                <span className={"text-sm"}>{userInfo.instTime}</span>
                            </td>
                        </tr>
                        <tr className={"border-1"}>
                            <td className={"w-[140px]"}>
                                <span className={"text-sm font-bold"}>추천인 허용</span>
                            </td>
                            <td>
                                <Switch
                                    onChange={value => {
                                        setUserInfo({
                                            ...userInfo,
                                            recommendYn: convertBoolean(value)
                                        })
                                    }}
                                    checked={convertBoolean(userInfo.recommendYn)}
                                    className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600">
                                    <span
                                        aria-hidden="true"
                                        className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                    />
                                </Switch>
                            </td>
                            <td className={"w-[140px]"}>
                                <span className={"text-sm font-bold"}>마지막 로그인</span>
                            </td>
                            <td>
                                <span className={"text-sm"}>{userInfo.lastLoginHistory ? userInfo.lastLoginHistory.instTime : ""}</span>
                            </td>
                        </tr>
                        <tr className={"border-1"}>
                            <td className={"w-[140px]"}>
                                <span className={"text-sm font-bold"}>추천인 보유 머니<br/>지급 회수 권한</span>
                            </td>
                            <td>
                                <Switch
                                    onChange={value => {
                                        setUserInfo({
                                            ...userInfo,
                                            moneyRetYn: convertBoolean(value)
                                        })
                                    }}
                                    checked={convertBoolean(userInfo.moneyRetYn)}
                                    className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600">
                                    <span
                                        aria-hidden="true"
                                        className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                    />
                                </Switch>
                            </td>
                            <td className={"w-[140px]"}>
                                <span className={"text-sm font-bold"}>마지막 로그인 IP</span>
                            </td>
                            <td>
                                <span>{userInfo.lastLoginHistory ? userInfo.lastLoginHistory.ip : ""}</span>
                            </td>
                        </tr>
                        <tr className={"border-1"}>
                            <td className={"w-[140px]"}>
                                <span className={"text-sm font-bold"}>추천인 보유 포인트<br />지급 회수 권한</span>
                            </td>
                            <td>
                                <Switch
                                    onChange={value => {
                                        setUserInfo({
                                            ...userInfo,
                                            pointRetYn: convertBoolean(value)
                                        })
                                    }}
                                    checked={resultBoolean(userInfo.pointRetYn)}
                                    className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600">
                                    <span
                                        aria-hidden="true"
                                        className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                    />
                                </Switch>
                            </td>
                            <td className={"w-[140px]"}>
                                <span className={"text-sm font-bold"}>로그인 가능 여부</span>
                            </td>
                            <td>
                                <Switch
                                    onChange={value => {
                                        setUserInfo({
                                            ...userInfo,
                                            status: convertBoolean(value)
                                        })
                                    }}
                                    checked={resultBoolean(userInfo.status)}
                                    className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600">
                                    <span
                                        aria-hidden="true"
                                        className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                    />
                                </Switch>
                            </td>
                        </tr>
                        <tr className={"border-1"}>
                            <td className={"w-[140px]"}>
                                <span className={"text-sm font-bold"}>{"환전 비밀번호"}</span>
                            </td>
                            <td>
                                <input className={"rounded border-1 px-2 text-sm"} type={"password"} value={userInfo.exchangePass}/>
                            </td>
                            <td className={"w-[140px]"}>
                                <span className={"text-sm font-bold"}>정지 설정</span>
                            </td>
                            <td>
                                <Switch
                                    onChange={value => {
                                        setUserInfo({
                                            ...userInfo,
                                            blackYn: convertBoolean(value)
                                        })
                                    }}
                                    checked={resultBoolean(userInfo.blackYn)}
                                    className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600">
                                    <span
                                        aria-hidden="true"
                                        className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"/>
                                </Switch>
                            </td>
                        </tr>
                        <tr className={"border-1"}>
                            <td className={"w-[140px]"}>
                                <span className={"text-sm font-bold"}>{"상위 파트너 리스트"}</span>
                            </td>
                            <td>
                                {userInfo.upperUserList.length > 0 &&
                                <div className={"flex flex-row gap-1 items-center"}>
                                    {userInfo.upperUserList.map(item => {
                                        return <span className={"text-xs"}><span className={"text-sm font-bold"}>{item}</span>{" >>"}</span>
                                    })}
                                </div>
                                }
                            </td>
                            <td className={"w-[140px]"}>
                                <span className={"text-sm font-bold"}>배팅 가능</span>
                            </td>
                            <td>
                                <Switch
                                    onChange={value => {
                                        setUserInfo({
                                            ...userInfo,
                                            bettingYn: convertBoolean(value)
                                        })
                                    }}
                                    checked={resultBoolean(userInfo.bettingYn)}
                                    className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600">
                                    <span
                                        aria-hidden="true"
                                        className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"/>
                                </Switch>
                            </td>
                        </tr>
                        <tr className={"border-1"}>
                            <td className={"w-[140px]"}>

                            </td>
                            <td>

                            </td>
                            <td className={"w-[140px]"}>
                                <span className={"text-sm font-bold"}>포인트 전환</span>
                            </td>
                            <td>
                                <Switch
                                    onChange={value => {
                                        setUserInfo({
                                            ...userInfo,
                                            transferPointYn: convertBoolean(value)
                                        })
                                    }}
                                    checked={resultBoolean(userInfo.transferPointYn)}
                                    className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600">
                                    <span
                                        aria-hidden="true"
                                        className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"/>
                                </Switch>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className={"flex flex-col"}>
                    <div className={"flex flex-row justify-between items-center bg-gray-100 p-2"}>
                        <span className={"text-md font-bold"}>메모</span>
                    </div>
                    <div className={"flex h-[100px]"}>
                        <textarea name={"filler1"} className={"flex-1 p-1 border-1 text-sm"} value={userInfo.filler1} onChange={handleUserInfo} />
                    </div>
                </div>
                <div className={"flex flex-col"}>
                    <div className={"flex flex-row items-center gap-2"}>
                        {/*<span className={"p-1 rounded border-2 border-indigo-500 text-sm font-bold"}>게임별 베팅설정(카지노)</span>*/}
                        {/*<span className={"p-1 rounded border-2 border-indigo-500 text-sm font-bold"}>게임별 베팅설정(슬롯)</span>*/}
                        {/*<span className={"p-1 rounded border-2 border-indigo-500 text-sm font-bold"}>게임별 베팅설정(미니게임)</span>*/}
                        <span className={"p-1 rounded-t border-t-2 border-x-2 border-indigo-500 text-sm font-bold"}>게임별 베팅설정(스포츠)</span>
                        {/*<span className={"p-1 rounded border-2 text-sm font-bold"}>받치기 요율 설정</span>*/}
                    </div>
                    <div className={"flex flex-row justify-between items-center bg-gray-100 p-2"}>
                        <span className={"text-md font-bold"}>게임목록</span>
                        <button className={"rounded text-xs text-white bg-gray-800 px-2 py-1"} onClick={saveSportsSetting}>저장</button>
                    </div>
                    <div className={"grid grid-cols-4 gap-2 p-2 border-1"}>
                        <div className={"flex flex-row gap-2 items-center"}>
                            <span className={"w-[80px] text-sm font-bold"}>스포츠</span>
                            <Switch
                                onChange={value => {
                                    setSportsSetting({
                                        ...sportsSetting,
                                        sportsYn: convertBoolean(value)
                                    })
                                }}
                                checked={convertBoolean(sportsSetting.sportsYn)}
                                className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600">
                                    <span
                                        aria-hidden="true"
                                        className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"/>
                            </Switch>
                        </div>
                        <div className={"flex flex-row gap-2 items-center"}>
                            <span className={"w-[80px] text-sm font-bold"}>카지노</span>
                            <Switch
                                onChange={value => {
                                    setSportsSetting({
                                        ...sportsSetting,
                                        casinoYn: convertBoolean(value)
                                    })
                                }}
                                checked={convertBoolean(sportsSetting.casinoYn)}
                                className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600">
                                    <span
                                        aria-hidden="true"
                                        className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"/>
                            </Switch>
                        </div>
                        <div className={"flex flex-row gap-2 items-center"}>
                            <span className={"w-[80px] text-sm font-bold"}>슬롯</span>
                            <Switch
                                onChange={value => {
                                    setSportsSetting({
                                        ...sportsSetting,
                                        slotYn: convertBoolean(value)
                                    })
                                }}
                                checked={convertBoolean(sportsSetting.slotYn)}
                                className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600">
                                    <span
                                        aria-hidden="true"
                                        className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"/>
                            </Switch>
                        </div>
                        <div className={"flex flex-row gap-2 items-center"}>
                            <span className={"w-[80px] text-sm font-bold"}>미니게임</span>
                            <Switch
                                onChange={value => {
                                    setSportsSetting({
                                        ...sportsSetting,
                                        minigameYn: convertBoolean(value)
                                    })
                                }}
                                checked={convertBoolean(sportsSetting.minigameYn)}
                                className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600">
                                    <span
                                        aria-hidden="true"
                                        className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"/>
                            </Switch>
                        </div>
                    </div>
                </div>
                <div className={"flex flex-col"}>
                    <div className={"flex flex-row items-center gap-2"}>
                        <span className={"p-1 rounded-t border-t-2 border-x-2 border-indigo-500 text-sm font-bold"}>게임별 요율설정</span>
                    </div>
                    <div className={"flex flex-row justify-between items-center bg-gray-100 p-2"}>
                        <span className={"text-md font-bold"}>게임목록</span>
                        <button className={"rounded text-xs text-white bg-gray-800 px-2 py-1"} onClick={saveSportsPer}>저장</button>
                    </div>
                    <div className="flex flex-col gap-4">
                        {/* 스포츠/미니게임 영역 */}
                        <div className="grid grid-cols-4 gap-4">
                            {userInfo.userRollingList
                                .filter(item => item.gameDiv === 'sport' || item.gameDiv === 'minigame')
                                .map((item, index) => {
                                    return <div key={`rolling_${item.seq}`} className={"border rounded p-2"}>
                                        <div className={"bg-gray-100 p-2 mb-2 font-bold text-sm"}>{item.gameNm}</div>
                                        <div className={"flex flex-col gap-2"}>
                                            <div className={"flex flex-col gap-1"}>
                                                <div className={"flex flex-row items-center gap-1"}>
                                                    <input 
                                                        className={"w-[50px] border px-1 text-sm"}
                                                        type="text"
                                                        value={item.rollingRate}
                                                        onChange={(e) => {
                                                            const inputValue = e.target.value;
                                                            if (inputValue === '' || isNaN(inputValue)) {
                                                                const updatedList = [...userInfo.userRollingList];
                                                                updatedList[index] = {
                                                                    ...item,
                                                                    rollingRate: inputValue
                                                                };
                                                                setUserInfo({
                                                                    ...userInfo,
                                                                    userRollingList: updatedList
                                                                });
                                                                return;
                                                            }

                                                            const numValue = parseFloat(inputValue);
                                                            
                                                            if (numValue > 100) {
                                                                window.alert('100%를 초과할 수 없습니다.');
                                                                return;
                                                            }
                                                            
                                                            if (item.isTopUser === 'N') {
                                                                if (numValue <= item.recommenderRollingRate) {
                                                                    const updatedList = [...userInfo.userRollingList];
                                                                    updatedList[index] = {
                                                                        ...item,
                                                                        rollingRate: inputValue
                                                                    };
                                                                    setUserInfo({
                                                                        ...userInfo,
                                                                        userRollingList: updatedList
                                                                    });
                                                                } else {
                                                                    window.alert(`상위수수료율(${item.recommenderRollingRate}%)을 초과할 수 없습니다.`);
                                                                }
                                                            } else {
                                                                const updatedList = [...userInfo.userRollingList];
                                                                updatedList[index] = {
                                                                    ...item,
                                                                    rollingRate: inputValue
                                                                };
                                                                setUserInfo({
                                                                    ...userInfo,
                                                                    userRollingList: updatedList
                                                                });
                                                            }
                                                        }}
                                                    />
                                                    <span className={"text-sm text-blue-500"}>% (상위수수료율 : {item.recommenderRollingRate})</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })}
                        </div>

                        {/* 카지노 영역 */}
                        <GameSection 
                            title="카지노"
                            gameDiv="casino"
                            defaultRate={casinoDefaultRate}
                            setDefaultRate={setCasinoDefaultRate}
                            userInfo={userInfo}
                            setUserInfo={setUserInfo}
                        />

                        {/* 슬롯 영역 */}
                        <GameSection 
                            title="슬롯"
                            gameDiv="slot"
                            defaultRate={slotDefaultRate}
                            setDefaultRate={setSlotDefaultRate}
                            userInfo={userInfo}
                            setUserInfo={setUserInfo}
                        />
                    </div>
                </div>
            </div>

            <CModal backdrop={"static"}
                    alignment={"center"}
                    visible={pointModal.visible}
                    onClose={() => {
                        setPointModal({visible: false, type: "0"})}}>
                <UserPointModal user={userInfo}
                                type={pointModal.type}
                                proc={(point) => {
                                    procPoint(point)
                                }}
                                close={() => {
                                    setPointModal({visible: false, type: "0"})
                                }}
                />
            </CModal>

            <CModal backdrop={"static"}
                    alignment={"center"}
                    visible={moneyModal.visible}
                    onClose={() => {
                        setMoneyModal({visible: false, type: "0"})}}>
                <UserMoneyModal user={userInfo}
                                type={moneyModal.type}
                                proc={(money) => {
                                    procMoney(money)
                                }}
                                close={() => {
                                    setMoneyModal({visible: false, type: "0"})
                                }}
                />
            </CModal>
        </div>
    )
}

const mapStateToProps = (state) => {
    const {account} = state;
    return {
        account
    }
}

export default connect(mapStateToProps, null)(UserDetail);
import React from "react";
import SettlePeriod from "../settle/SettlePeriod";

const Dashboard = React.lazy(() => import("../dashboard/Dashboard"))
const PartnerList = React.lazy(() => import("../partner/PartnerList"))
const PartnerList2 = React.lazy(() => import("../partner/PartnerList2"))
const PartnerCreate = React.lazy(() => import("../partner/PartnerCreate"))
const UserCreate = React.lazy(() => import("../user/UserCreate"))
const UserList = React.lazy(() => import("../user/UserList"))
const UserListPartner = React.lazy(() => import("../user/UserListPartner"))
const UserAccept = React.lazy(() => import("../user/UserAccept"))
const CashHistory = React.lazy(() => import("../user/CashHistory"))
const UserPointList = React.lazy(() => import("../user/UserPointList"))
const UserHistory = React.lazy(() => import("../user/UserHistory"))
const LoginHistory = React.lazy(() => import("../user/LoginHistory"))
const LoginUserList = React.lazy(() => import("../user/LoginUserList"))
const UserMultiIpList = React.lazy(() => import("../user/UserMultiIpList"))
const BettingList = React.lazy(() => import("../betting/BettingList"))
const CashInRequestList = React.lazy(() => import("../cash/CashInRequestList"))
const CashInList = React.lazy(() => import("../cash/CashInList"))
const CashWithdrawRequestList = React.lazy(() => import("../cash/CashWithdrawRequestList"))
const CashWithdrawList = React.lazy(() => import("../cash/CashWithdrawList"))
const CashAdminProcList = React.lazy(() => import("../cash/CashAdminProcList"))
const CashChangeHistory = React.lazy(() => import("../cash/CashChangeHistoryList"))
const SettlePartners = React.lazy(() => import("../settle/SettlePartners"))
const SettlePartners2 = React.lazy(() => import("../settle/SettlePartners2"))
const SettleUsers = React.lazy(() => import("../settle/SettleUsers"))
const SettleGames = React.lazy(() => import("../settle/SettleGames"))
const SettleDaily = React.lazy(() => import("../settle/SettlePeriod"))
const SettleMonthly = React.lazy(() => import("../settle/SettleMonthly"))
const GameSportsList = React.lazy(() => import("../games/GameSportsList"))
const CustomerMemo = React.lazy(() => import("../board/CustomerMemoList"))
const CustomerOneToOne = React.lazy(() => import("../board/CustomerOneToOneList"))
const CustomerNotice = React.lazy(() => import("../board/CustomerNoticeList"))
const CustomerFaq = React.lazy(() => import("../board/CustomerFaqList"))
const ConfigEvent = React.lazy(() => import("../config/ConfigEvent"))
const ConfigBank = React.lazy(() => import("../config/ConfigBank"))
const ConfigBankGrade = React.lazy(() => import("../config/ConfigBankGrade"))
const ConfigDomain = React.lazy(() => import("../config/ConfigDomain"))
const ConfigPopup = React.lazy(() => import("../config/ConfigPopup"))
const ConfigInspection = React.lazy(() => import("../config/ConfigInspection"))
const ConfigOption = React.lazy(() => import("../config/ConfigOption"))
const ConfigBlock = React.lazy(() => import("../config/ConfigBlock"))
const AdminList = React.lazy(() => import("../admin/AdminList"))
const AdminJoinIpList = React.lazy(() => import("../admin/AdminJoinIp"))
const AdminLogs = React.lazy(() => import("../admin/AdminLogs"))
const SiteSetting = React.lazy(() => import("../config/SiteSetting"))
const ConfigSite = React.lazy(() => import("../config/SiteSetting"))
const ConfigSiteUserLevel = React.lazy(() => import("../config/LevelSetting"))
const LogViewer = React.lazy(() => import("../config/LogViewer"))

const Components = {
	"/main": Dashboard,
	"/dashboard": Dashboard,
	"/partner/list": PartnerList,
	"/partner/list2": PartnerList2,
	"/partner/create": PartnerCreate,
	"/user/create": UserCreate,
	"/user/list": UserList,
	"/user/list-partner": UserListPartner,
	"/user/accept": UserAccept,
	"/user/cash-history": CashHistory,
	"/user/point": UserPointList,
	"/user/login/history": LoginHistory,
	"/user/history": UserHistory,
	"/user/login-user": LoginUserList,
	"/user/multi-ip": UserMultiIpList,
	"/betting/list": BettingList,
	"/cash/in-request": CashInRequestList,
	"/cash/in-list": CashInList,
	"/cash/withdraw-request": CashWithdrawRequestList,
	"/cash/withdraw-list": CashWithdrawList,
	"/cash/admin": CashAdminProcList,
	"/cash/change-history": CashChangeHistory,
	"/settle/partner-list1": SettlePartners,
	"/settle/partner-list2": SettlePartners2,
	"/settle/user-list": SettleUsers,
	"/settle/game-list": SettleGames,
	"/settle/daily": SettlePeriod,
	"/settle/monthly": SettleMonthly,
	"/game/sports": GameSportsList,
	"/customer/memo": CustomerMemo,
	"/customer/one-to-one": CustomerOneToOne,
	"/customer/notice": CustomerNotice,
	"/customer/faq": CustomerFaq,
	"/config/event": ConfigEvent,
	"/config/default-bank": ConfigBank,
	"/config/bank-grade": ConfigBankGrade,
	"/config/domain": ConfigDomain,
	"/config/inspection": ConfigInspection,
	"/config/option": ConfigOption,
	"/config/popup": ConfigPopup,
	"/config/block": ConfigBlock,
	"/admin/list": AdminList,
	"/admin/ip-list": AdminJoinIpList,
	"/admin/logs": AdminLogs,
	"/config/site": SiteSetting,
	"/config/level": ConfigSiteUserLevel,
	"/config/log": LogViewer
}

export default (block, props) => {
	if (typeof Components[block.to] !== undefined) {
		return React.createElement(Components[block.to], props)
	}
}

import React, {useEffect, useState} from 'react'
import { AppContent, AppSidebar, AppFooter, AppHeader } from './index'

import {connect, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {CContainer, CNav, CNavGroup, CNavItem} from "@coreui/react";
import {footerActions} from "../../../redux/actions/footer";
import Loading from "../../common/Loading"
import Navigation from "../../../_nav";

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const DefaultLayout = (props) => {
    const navigate = useNavigate();
    const {user} = useSelector((props) => props.account);

    const menu = Navigation();
    const [loading, setLoading] = useState(false);
    const [userMenuList, setUserMenuList] = useState([]);
    const [selectedMenu, setSelectedMenu] = useState(menu[0]);

    useEffect(() => {
        if (user === undefined) {
            navigate("/login");
            return;
        }
        // getUserMenuList();
    }, [])

    useEffect(() => {
        const handleMessage = (event) => {
            if (event.origin !== window.location.origin) return
            const message = event.data
            if (event.type === "message") {
                if (message.length > 0) {
                    // message 분리
                    const div = message.split(",")
                    pageMove(div)
                }
            }
        }
        window.addEventListener("message", handleMessage)

        return () => {
            window.removeEventListener("message", handleMessage)
        }
    }, [])

    const pageMove = (obj) => {
        let menu = {}
        console.log("### obj :: ", obj[1] === "betting")
        switch (obj[1]) {
            case "deposit":
                menu = {
                    id: 41,
                    component: CNavItem,
                    name: '입금 신청 내역',
                    to: '/cash/in-request',
                }
                break;
            case "withdraw":
                menu = {
                    id: 44,
                    component: CNavItem,
                    name: '출금 내역 조회',
                    to: '/cash/withdraw-list',
                }
                break;
            case "point":
                menu = {
                    id: 26,
                    component: CNavItem,
                    name: '포인트 관리',
                    to: '/user/point',
                }
                break;
            case "loginLog":
                menu = {
                    id: 83,
                    component: CNavItem,
                    name: "로그",
                    to: '/config/log'
                }
                break;
            case "betting":
                menu = {
                    id: 31,
                    component: CNavItem,
                    name: '통합 배팅내역',
                    to: '/betting/list',
                }
                break;
        }
        goMenu(menu)
    }

    const goMenu = (menu) => {
        navigate(menu.to);
        setSelectedMenu(menu)
    }

    return (
        <>
            {user !== undefined &&
            <div>
                <AppSidebar setSelectedMenu={setSelectedMenu} userMenuList={userMenuList}/>
                <div className="wrapper d-flex flex-column min-vh-100">
                    <AppHeader move={goMenu}/>
                    <div className="body flex-grow-1 px-3">
                        <AppContent />
                    </div>
                </div>  
            </div>
            }
        </>
    )
}

const mapStateToProps = (state) => {
    const {account, store} = state;
    return {
        account, store
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        delFooterMenu: (menu) => {
            dispatch(footerActions.delFooterMenu(menu));
        },
        addFooterMenu: (menu) => {
            dispatch(footerActions.addFooterMenu(menu));
        },
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);

import {connect, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import dayjs from "dayjs";
import Loading from "../../common/Loading";
import InputRangeDate from "../../common/InputRangeDate";
import {AgGridReact} from "ag-grid-react";
import * as api from "../../../api/api";
import * as Global from "../../common/Global";

/* 기간별 정산 내역 */
const SettlePeriod = (props) => {
    const {user} = useSelector((props) => props.account);
    const [loading, setLoading] = useState(false)
    const [searchForm, setSearchForm] = useState({
        startTime: dayjs().add(-7, "d"),
        endTime: dayjs()
    })
    const [settleData, setSettleData] = useState([])
    const [totalRow, setTotalRow] = useState([])

    const defaultColDef = {
        flex: 1,
        resizable: true,
        sortable: true,
        filter: false,
        autoHeaderHeight: true
    }

    useEffect(() => {
        search()
    }, [searchForm])

    const handleWhere = (e) => {
        const {name, value} = e.target;
        setSearchForm({
            ...searchForm,
            [name]: value
        })
    }

    const handleDate = (date) => {
        setSearchForm({
            ...searchForm,
            startTime: date[0],
            endTime: date[1]
        })
    }

    const search = () => {
        const params = {
            companyCode: user.companyCode,
            fromDate: searchForm.startTime.format("YYYYMMDD"),
            toDate: searchForm.endTime.format("YYYYMMDD")
        }

        setLoading(true)
        api.getDailyStatistics(params).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                setSettleData(data)
                const total = {
                    occurDt: '합계',
                    depositAmount: data.reduce((sum, row) => sum + (row.depositAmount || 0), 0),
                    withdrawAmount: data.reduce((sum, row) => sum + (row.withdrawAmount || 0), 0),
                    bettingAmount: data.reduce((sum, row) => sum + (row.bettingAmount || 0), 0),
                    bettingWinningAmount: data.reduce((sum, row) => sum + (row.bettingWinningAmount || 0), 0),
                    betProfitAmount: data.reduce((sum, row) => sum + (row.betProfitAmount || 0), 0),
                    userCount: '',
                    bettingUserCount: '',
                    userHaveAmount: data.reduce((sum, row) => sum + (row.userHaveAmount || 0), 0),
                    managerHaveAmount: data.reduce((sum, row) => sum + (row.managerHaveAmount || 0), 0),
                    gameFee: data.reduce((sum, row) => sum + (row.gameFee || 0), 0),
                    profitFeeAmount: data.reduce((sum, row) => sum + (row.profitFeeAmount || 0), 0)
                }
                setTotalRow([total])
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const [columnDefs] = useState([
        {
            field: 'occurDt', 
            headerName: '날짜', 
            valueGetter: params => {
                if (params.data.occurDt === '합계') return '합계';
                return dayjs(params.data.occurDt, 'YYYYMMDD').format('YYYY-MM-DD')
            }, 
            cellClass: "static-cell"
        },
        {field: 'depositAmount', headerName: "입금액", valueFormatter: Global.currencyFormatter, cellClass: "number-cell"},
        {field: 'withdrawAmount', headerName: "출금액", valueFormatter: Global.currencyFormatter, cellClass: "number-cell"},
        {
            field: 'profitAmount', 
            headerName: "수익금", 
            valueGetter: params => {
                return params.data.depositAmount - params.data.withdrawAmount;
            },
            valueFormatter: Global.currencyFormatter, 
            cellClass: params => {
                const value = params.data.depositAmount - params.data.withdrawAmount;
                return value >= 0 ? ['number-cell', 'text-green-600'] : ['number-cell', 'text-red-600'];
            }
        },
        {field: 'bettingAmount', headerName: "배팅금", valueFormatter: Global.currencyFormatter, cellClass: "number-cell"},
        {field: 'bettingWinningAmount', headerName: "배팅당첨금", valueFormatter: Global.currencyFormatter, cellClass: "number-cell"},
        {
            field: 'betProfitAmount', 
            headerName: "배팅수익금", 
            valueGetter: params => {
                return params.data.bettingAmount - params.data.bettingWinningAmount;
            },
            valueFormatter: Global.currencyFormatter, 
            cellClass: params => {
                const value = params.data.bettingAmount - params.data.bettingWinningAmount;
                return value >= 0 ? ['number-cell', 'text-green-600'] : ['number-cell', 'text-red-600'];
            }
        },
        {
            field: 'userCount', 
            headerName: "회원수", 
            cellClass: "number-cell",
            valueFormatter: params => params.value === '' ? '' : params.value
        },
        {
            field: 'bettingUserCount', 
            headerName: "배팅회원수", 
            cellClass: "number-cell",
            valueFormatter: params => params.value === '' ? '' : params.value
        },
        {field: 'userHaveAmount', headerName: "회원보유금", valueFormatter: Global.currencyFormatter, cellClass: "number-cell"},
        {field: 'managerHaveAmount', headerName: "보유수수료", valueFormatter: Global.currencyFormatter, cellClass: "number-cell"},
        {field: 'gameFee', headerName: "총수수료", valueFormatter: Global.currencyFormatter, cellClass: "number-cell"},
        {
            field: 'profitFeeAmount', 
            headerName: "수익-수수료", 
            valueGetter: params => {
                const betProfit = params.data.bettingAmount - params.data.bettingWinningAmount;
                return betProfit - params.data.gameFee;
            },
            valueFormatter: Global.currencyFormatter, 
            cellClass: params => {
                const betProfit = params.data.bettingAmount - params.data.bettingWinningAmount;
                const value = betProfit - params.data.gameFee;
                return value >= 0 ? ['number-cell', 'text-green-600'] : ['number-cell', 'text-red-600'];
            }
        }
    ])

    return (
        <div className={"flex flex-col"}>
            {loading ? <Loading /> : null}
            <p className={"text-md font-bold"}>{"정산 관리 > 일자별 정산내역"}</p>
            <div className={"bg-gray-100 border"}>
                <div className={"flex flex-row p-2 gap-1"}>
                    <InputRangeDate title={"날짜"} startTime={searchForm.startTime} endTime={searchForm.endTime} setValue={handleDate} />
                    <button className={"bg-o2-blue px-3 rounded text-white text-sm"} onClick={search}>조회</button>
                </div>
            </div>
            <h6 className={"text-md font-bold mt-4"}>합계</h6>
            <div className="ag-theme-alpine mt-1" style={{width: '100%', height: 600}}>
                <AgGridReact
                    rowSelection={'single'}
                    rowHeight={32}
                    headerHeight={32}
                    rowData={settleData}
                    pagination={true}
                    defaultColDef={defaultColDef}
                    columnDefs={columnDefs}
                    pinnedBottomRowData={totalRow}
                    >
                </AgGridReact>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const {account, store} = state;
    return {
        account, store
    }
}

export default connect(mapStateToProps, null)(SettlePeriod);
import React from 'react'

const Loading = () => {
	return (
		<div 
			className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
			style={{cursor: 'wait'}}
		>
			<div className="flex flex-col items-center bg-white p-5 rounded-lg">
				<div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-900"></div>
			</div>
		</div>
	);
};

export default Loading;